import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HotToastService } from '@ngneat/hot-toast';
// import { SocketService } from '../../../shared/services/socket/socket.service';
import { UserService } from '../../../shared/services/user/user.service';
import { ThemeService } from '../../../shared/services/theme/theme.service';

export const authGuard: CanActivateChildFn = async (_, __) => {
  const routerService = inject(Router);
  const authService = inject(AuthService);
  const userService = inject(UserService);
  const themeService = inject(ThemeService);
  // const socketService = inject(SocketService);
  const toastService = inject(HotToastService);

  if (!authService.token) {
    // socketService.disconnect();
    await routerService.navigate(['login']);
    return false;
  }

  try {
    const user = await userService.getCurrentUser();
    userService.user.set(user);
    themeService.color = user.favoriteColor;
    // socketService.connect();
    return true;
  } catch (e) {
    userService.user.set(undefined);
    const error = e as HttpErrorResponse;
    toastService.show(error.error.error, {
      style: { color: 'var(--error)', background: 'var(--on-error)' },
    });
    // socketService.disconnect();
    await routerService.navigate(['login']);
    return false;
  }
};
