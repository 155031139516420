import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ThemeService } from './shared/services/theme/theme.service';
import { MixpanelService } from './shared/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  themeService = inject(ThemeService);
  mixpanelService = inject(MixpanelService);

  ngOnInit(): void {
    this.addThemeListener();
    this.mixpanelService.init();
  }

  addThemeListener() {
    const darkModePreference = window.matchMedia(
      '(prefers-color-scheme: dark)',
    );

    if (darkModePreference.matches) this.setTheme('dark');
    if (!darkModePreference.matches) this.setTheme('light');

    darkModePreference.addEventListener('change', (e) => {
      if (e.matches) this.setTheme('dark');
      if (!e.matches) this.setTheme('light');
    });
  }

  setTheme(theme: 'light' | 'dark') {
    this.themeService.systemTheme.set(theme);
    this.themeService.getAppTheme();
  }
}
