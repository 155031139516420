import { Routes } from '@angular/router';
import { authGuard } from './domains/auth/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./domains/auth/auth.routes').then((m) => m.authRoutes),
  },
  {
    path: 'chats',
    loadChildren: () =>
      import('./domains/chat/chat.routes').then((m) => m.chatRoutes),
    canActivateChild: [authGuard],
  },
];
